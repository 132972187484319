import firebase from 'firebase/app';
import 'firebase/auth';
import { logError } from '../logging';
import axios from 'axios';
import { axiosUseDirectFunctionUrl } from './axiosUseDirectFunctionUrl';

export const isCanceledError = (error) => {
  return (
    !!error.isAxiosError && !error.response && error.name === 'CanceledError'
  );
};

export const InitAxios = (user) => {
  try {
    const instance = axios.create({
      baseURL: window.ResourceAuthorizer.getServiceUrl('auth', '/api/cert'),
      timeout: 100000,
      headers: {
        'content-type': 'application/json',
      },
    });

    axiosUseDirectFunctionUrl(instance);

    instance.interceptors.request.use(async (config) => {
      let token = null;
      let jct = null;

      function getToken() {
        if (!window.agent) {
          throw new Error('This action requires a loggedin session');
        }
        return window.agent.tokenManager.getToken.apply(
          window.agent.tokenManager,
          arguments,
        );
      }

      // UNCOMMENT TO DEBUG REQUESTS
      // console.log('request config', config);

      switch (config.caller) {
        default:
        case 'auth':
          const user = firebase.auth().currentUser;
          if (!user) {
            throw new Error('no user authenticated');
          }
          config.headers.authorization = 'Bearer ' + (await user.getIdToken());
          break;

        case 'event':
          token = await getToken('auth:event', 30);
          config.headers.authorization = 'Bearer ' + token;
          break;
        case 'customer.search':
          token = await getToken('res:datariver:teamUuid', 30, {
            teamUuid: config.teamUuid,
          });
          config.headers.authorization = 'Bearer ' + token;
          break;

        case 'payment':
          token = await getToken('auth:payment', 30);
          config.headers.authorization = 'Bearer ' + token;
          break;

        case 'team':
          token = await getToken('auth:team', 30);
          config.headers.authorization = 'Bearer ' + token;
          break;

        case 'team.team':
          token = await getToken('res:team:teamUuid', 30, {
            teamUuid: config.teamUuid,
          });
          jct = new window.jwtCertSDK.JCT(token);
          config.url = config.url.replace(
            '{teamUuid}',
            jct.service_authorized_resource_uri,
          );
          config.headers.authorization = 'Bearer ' + token;
          break;
        case 'team.event':
          token = await getToken('res:team:event:teamUuid', 30, {
            teamUuid: config.teamUuid,
          });
          jct = new window.jwtCertSDK.JCT(token);
          config.url = config.url.replace(
            '{teamUuid}',
            jct.service_authorized_resource_uri,
          );
          config.headers.authorization = 'Bearer ' + token;
          break;

        case 'team.payment':
          token = await getToken('res:team:payment:teamUuid', 30, {
            teamUuid: config.teamUuid,
          });
          jct = new window.jwtCertSDK.JCT(token);
          config.url = config.url.replace(
            '{teamUuid}',
            jct.service_authorized_resource_uri,
          );
          config.headers.authorization = 'Bearer ' + token;
          break;

        case 'sales':
          token = await getToken('res:sales:eventId:teamUuid', 30, {
            teamUuid: config.teamUuid,
            eventId: config.eventId,
          });
          jct = new window.jwtCertSDK.JCT(token);
          config.url = config.url.replace(
            '{eventId}',
            jct.service_authorized_resource_uri,
          );
          config.headers.authorization = 'Bearer ' + token;
          break;

        case 'sales.team':
          token = await getToken('res:sales:teamUuid', 30, {
            teamUuid: config.teamUuid,
          });
          jct = new window.jwtCertSDK.JCT(token);
          config.url = config.url.replace(
            '{teamUuid}',
            jct.service_authorized_resource_uri,
          );
          config.headers.authorization = 'Bearer ' + token;
          break;

        case 'access-control':
          token = await getToken('res:access-control:eventId:teamUuid', 30, {
            teamUuid: config.teamUuid,
            eventId: config.eventId,
          });
          jct = new window.jwtCertSDK.JCT(token);
          config.url = config.url.replace(
            '{eventId}',
            jct.service_authorized_resource_uri,
          );
          config.headers.authorization = 'Bearer ' + token;
          break;

        case 'datariver':
          token = await getToken('res:datariver:teamUuid', 30, {
            teamUuid: config.teamUuid,
          });
          config.headers.authorization = 'Bearer ' + token;
          break;
        case 'datariver.event':
          token = await getToken('res:datariver:eventId:teamUuid', 30, {
            teamUuid: config.teamUuid,
            eventId: config.eventId,
          });
          config.headers.authorization = 'Bearer ' + token;
          break;
        case 'team.ledger':
          token = await getToken('res:teamUuid:ledger', 30, {
            teamUuid: config.teamUuid,
          });
          config.headers.authorization = 'Bearer ' + token;
          break;
      }
      return config;
    });

    const autoRetry = curryAxiosRetryInterceptor(instance, 4);
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        // https://intellitixtech.atlassian.net/browse/CBNK-2002

        // This is in place to retry a call when there's an issue before we
        // reach our backend.

        // 500+ errors are considered runtime / execution error, and therefore
        // we nede to retry the call to give the network / servers another
        // chance
        const isGenericError = error.response?.status >= 500;

        // When said error occures on a preflight call, the status code returned
        // is 0, with a generic Network Error message. Unless there's a better
        // way to detect this, we retry as soon as we have the status code of 0
        const isPreflightError = error.response?.status === 0;

        if (isGenericError || isPreflightError) {
          return autoRetry(error, error.config);
        }
        return Promise.reject(error);
      },
    );

    window.api = instance;
  } catch (e) {
    logError(e, 'InitAxios', { user: user });
  }
};

/**
 * Used to allow a JCT to be logged without keeping the signature information,
 * rendering it invalid, but keeps all the pertinent information
 */
function redactJCTSignatures(rawJct) {
  return rawJct
    .split('.')
    .map((part) => (part.startsWith('eyJ') ? part : 'redacted'))
    .join('.');
}

const RETRY_DELAYS = [0, 500, 2000, 5000];
function curryAxiosRetryInterceptor(instance, defaultMaxRetries = 4) {
  return function axiosRetryInterceptor(error, orgConfig) {
    // retry the api call, if we haven't reach the maximum amount of retries

    const max_retries = orgConfig.max_retries ?? defaultMaxRetries;
    const attempt_count = (orgConfig.attempt_count ?? 0) + 1;

    if (attempt_count < max_retries) {
      // wait a little before retrying, waiting longuer between consecutive
      // faillures
      const retryingIn =
        attempt_count < RETRY_DELAYS.length
          ? RETRY_DELAYS[attempt_count]
          : RETRY_DELAYS[RETRY_DELAYS.length - 1];
      const { authorization, ...headers } = orgConfig.headers;
      const replayConfig = { ...orgConfig, headers };
      const { request, response: baseResponse, config, ...baseError } = error;
      const { config: _dropIt, ...response } = baseResponse;
      logError(
        {
          attempt_count,
          max_retries,
          retryingIn,
          error: baseError,
        },
        'axios-auto-retry',
        {
          config: {
            ...config,
            headers: {
              ...config.headers,
              authorization: authorization?.startsWith('Bearer ')
                ? 'Bearer ' +
                  redactJCTSignatures(authorization.substring('Bearer '.length))
                : undefined,
            },
          },
          request,
          response,
        },
        true,
      );
      return new Promise((resolve, reject) => {
        setTimeout(
          () =>
            instance
              .request({
                ...replayConfig,
                attempt_count,
                max_retries,
              })
              .then(resolve, reject),
          retryingIn,
        );
      });
    }

    return Promise.reject(error);
  };
}
