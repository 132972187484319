import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getItemFromStore, setItemToStore, themeColors } from './utils'; // getItemFromStore, setItemToStore,
// import { AuthInit, UserDef } from 'helpers/api/authentication';
import useApplicationUser from '../hooks/useApplicationUser';
import { useGuestsSearchState } from 'layouts/producer/Customers/CustomerList/hooks/useGuestsSearchState';

const AppContext = createContext();

export function Provider(props) {
  const {
    user,
    setUser,
    agent,
    setAgent,
    activeTeam,
    setActiveTeam,
    isActiveTeamParentLenusa,
  } = useApplicationUser();
  const navbarBreakPoint = 'xl';
  const topNavbarBreakpoint = 'lg';
  const isFluid = true;
  const navbarStyle = 'card';
  const toggleModal = false;
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [currency, setCurrency] = useState('$');
  const [isDark, setIsDark] = useState(false); // getItemFromStore('isDark', false)
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] =
    useState(false); // getItemFromStore('isNavbarVerticalCollapsed', false)

  const [showProducerMenu, setShowProducerMenu] = useState(true);

  const [rfidCredentialsEnabled, setRfidCredentialsEnabled] = useState(false);

  const [loginFailed, setLoginFailed] = useState('');
  const [newAccountEmail, setNewAccountEmail] = useState('');
  const [passwordResetEmail, setPasswordResetEmail] = useState('');
  const [passwordResetChangeEmail, setPasswordResetChangeEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [rememberMe, setRememberMe] = useState(
    getItemFromStore('rememberMe', ''),
  );

  const guestsSearchState = useGuestsSearchState();

  const value = {
    showProducerMenu,
    setShowProducerMenu,

    user,
    setUser,
    agent,
    setAgent,
    activeTeam,
    setActiveTeam,
    isActiveTeamParentLenusa,
    rememberMe,
    setRememberMe,
    isLoading,
    setIsLoading,
    rfidCredentialsEnabled,
    setRfidCredentialsEnabled,

    loginFailed,
    setLoginFailed,
    newAccountEmail,
    setNewAccountEmail,
    passwordResetEmail,
    setPasswordResetEmail,
    passwordResetChangeEmail,
    setPasswordResetChangeEmail,

    navbarBreakPoint,
    topNavbarBreakpoint,
    isDark,
    setIsDark,
    isFluid,
    currency,
    toggleModal,
    navbarStyle,
    setCurrency,
    isOpenSidePanel,
    showBurgerMenu,
    setShowBurgerMenu,
    setIsOpenSidePanel,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    guestsSearchState: { ...guestsSearchState },
  };

  useEffect(() => {
    setItemToStore('rememberMe', rememberMe);
  }, [rememberMe]);

  if (!isLoaded) {
    const styleSheet = document.createElement('link');
    styleSheet.href = `${process.env.PUBLIC_URL}/css/theme.css`;
    styleSheet.type = 'text/css';
    styleSheet.rel = 'stylesheet';
    styleSheet.className = 'theme-stylesheet';
    styleSheet.onload = setIsLoaded(true);
    document.getElementsByTagName('head')[0].appendChild(styleSheet);
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: themeColors.light,
        }}
      />
    );
  }
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

Provider.propTypes = {
  user: PropTypes.object,
  users: PropTypes.array,
  selectedUser: PropTypes.object,
};

Provider.defaultProps = {
  user: {},
  users: [],
  selectedUser: {},
};

export default AppContext;
