export function logError(error, source, params = {}, hideModal = false) {
  console.log('ERROR------------');
  console.log(error);
  console.log(params);

  if (!hideModal) {
    window.postMessage(
      JSON.parse(
        JSON.stringify({
          error: true,
          source,
          description: error.toString(),
          params,
          trace: error.stack,
        }),
      ),
    );
  }
}
