import { logError } from 'helpers/logging';
import { CustomerNote } from 'types';

export const GetFolios = async (
  teamUuid,
  type = 'ticketing',
  opts = { includeDescendantsCount: false },
) => {
  try {
    let aggregatedResults = [];
    let hasMore = true;
    let currentResponse;

    let pageRetrieveCounter = 0;

    while (hasMore && pageRetrieveCounter <= 1000) {
      pageRetrieveCounter++;
      const params = {
        ...(currentResponse?.data.page.nextPageToken && {
          nextPageToken: currentResponse.data.page.nextPageToken,
        }),
        pageSize: 100,
      };
      if (opts?.includeDescendantsCount) {
        params.includeDescendantsCount = opts.includeDescendantsCount;
      }
      currentResponse = await window.api.request({
        caller: 'sales.team',
        url: `/api/folios?folioType=${type}`,
        method: 'get',
        baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
        params,
        teamUuid,
      });
      aggregatedResults = [
        ...aggregatedResults,
        ...currentResponse.data.entries,
      ];
      hasMore = currentResponse.data.page.hasMore;
    }

    return {
      status: 200,
      folios: aggregatedResults,
    };
  } catch (e) {
    logError(e, 'GetFolios', {
      teamUuid,
    });
    return {
      status: 400,
      folios: [],
    };
  }
};

export const GetTeamFolio = async (teamUuid) => {
  let teamFolio;
  // TEMP: Hardcoded check for LENUSA Team UUID, and lookup by teamFolioUUID
  if (
    process.env.REACT_APP_ENV === 'production' &&
    teamUuid === 'EE6pJ8YKlydT1GVS4HFu'
  ) {
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/folios/t6gyLoQjWU8Lev3xd2qO',
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
    });

    teamFolio = response.data.entry;
  } else {
    const foliosResponse = await GetFolios(teamUuid, 'team');
    teamFolio = foliosResponse.folios.find((folio) => folio.name === teamUuid);
  }
  if (!teamFolio) {
    throw new Error('Missing required team folio for team ' + teamUuid);
  }
  return teamFolio;
};

export const GetFolioByEventId = async (eventUuid, teamUuid) => {
  try {
    const fullUri = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventUuid,
    );
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/folios/' + fullUri,
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
    });

    return response.data.entry;
  } catch (e) {
    if (e.response.status !== 404) {
      logError(e, 'GetFolioByEventId', { eventUuid, teamUuid });
    }
    return undefined;
  }
};

export const GetFolioDetails = async (teamUuid, folioId) => {
  const response = await window.api.request({
    caller: 'sales.team',
    url: '/api/folios/' + folioId,
    method: 'get',
    baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
    teamUuid,
  });

  return response.data.entry;
};

export const GetSalesChannelsLinkedToFolio = async (
  folioUuid,
  teamUuid,
  inflateSalesChannels = false,
) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/folios/' + folioUuid + '/salesChannels',
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      params: {
        inflateSalesChannels,
      },
      teamUuid,
    });

    return response.data.salesChannels;
  } catch (e) {
    logError(e, 'GetSalesChannelsLinkedToFolio', {
      folioUuid,
      teamUuid,
    });
    return [];
  }
};

export const getCurrencyCode = async (folioUuid: string, teamUuid: string) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/folios/' + folioUuid + '/salesSummary/currencyCode',
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
    });

    return response.data.currencyCode;
  } catch (e) {
    logError(e, 'GetCurrencyCode', {
      folioUuid,
      teamUuid,
    });
    return '';
  }
};

export const CreateTopUpFolio = async (
  parentFolioUuid,
  teamUuid,
  ledgerUuid,
) => {
  try {
    const data = {
      parentFolioUuid,
      name: `topup-${teamUuid}`,
      folioType: 'topup',
      ledgerUuid,
    };
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/folios',
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      data,
      teamUuid,
    });
    return {
      status: 200,
      folio: response.data.entry,
    };
  } catch (e) {
    logError(e, 'CreateTopUpFolio', {
      parentFolioUuid,
    });
    return {
      status: 400,
      folio: {},
    };
  }
};

export const GetFolioDescendants = async (
  parentFolioUuid,
  teamUuid,
  folioType = null,
) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${parentFolioUuid}/descendants`,
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
      data: { folioType },
    });
    return response.data.entries;
  } catch (e) {
    logError(e, 'GetFolios');
    return {
      status: 400,
      folio: {},
    };
  }
};

export const CreateTeamFolio = async (teamUuid, parentTeamUuid) => {
  try {
    const data = {
      name: teamUuid,
      folioType: 'team',
    };
    if (parentTeamUuid) {
      const parentTeamFolio = await GetTeamFolio(parentTeamUuid);
      const token = await window.agent.tokenManager.getToken(
        'res:sales:teamUuid',
        300,
        { teamUuid: parentTeamUuid },
      );
      data.parentFolioUuid = parentTeamFolio.uuid;
      data.$parentFolioJCT = token;
    }
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/folios',
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      data,
      teamUuid,
    });

    return {
      status: 200,
      folio: response.data.entry,
    };
  } catch (e) {
    logError(e, 'GetFolios', {
      teamUuid,
    });
    return {
      status: 400,
      folio: {},
    };
  }
};

export const CreateEventFolio = async (eventId, teamUuid, folioType = null) => {
  try {
    const data = {
      eventUuid: eventId,
      name: eventId,
    };
    if (folioType) {
      data['folioType'] = folioType;
    }
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/folios',
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      data: data,
      teamUuid,
    });

    return {
      status: 200,
      folios: response.data.entries,
    };
  } catch (e) {
    logError(e, 'GetFolios', {
      teamUuid,
    });
    return {
      status: 400,
      folios: {},
    };
  }
};

export const UpdateEventsRequiringFolioTicketingCreation = async (
  events,
  folios,
  teamUuid,
) => {
  try {
    let newFolios = [...folios];
    const foliosResponse = await GetFolios(teamUuid, 'team');
    const teamFolio = foliosResponse.folios.find(
      (folio) => folio.name === teamUuid,
    );
    if (!teamFolio) {
      throw new Error('missing team folio');
    }

    for (const event of events) {
      if (
        !folios.some(
          (folio) =>
            folio.name === event.uuid && folio.folioType === 'ticketing',
        )
      ) {
        const folioResponse = await CreateFolioFromImplied(
          event.uuid,
          teamFolio.uuid,
          teamUuid,
          false,
        );
        newFolios = [...newFolios, folioResponse.folio];
      }
    }

    return newFolios;
  } catch (e) {
    logError(e, 'UpdateEventsRequiringFolioTicketingCreation', {
      events,
      folios,
      teamUuid,
    });
    return [];
  }
};

// this creates a ticketing folio for an event
export const CreateFolioFromImplied = async (
  eventId,
  teamFolioUuid,
  teamUuid,
) => {
  try {
    if (!eventId || !teamFolioUuid || !teamUuid) {
      throw new Error('Missing required value');
    }

    const fullUri = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventId,
    );
    const token = await window.agent.tokenManager.getToken(
      'res:sales:teamUuid',
      30,
      { teamUuid },
    );
    const response = await window.api.request({
      caller: 'sales',
      url: '/api/folios/' + fullUri,
      method: 'patch',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      data: {
        modifiedOn: new Date().toISOString(),
        parentFolioUuid: teamFolioUuid,
        $parentFolioJCT: token,
        name: eventId,
        folioType: 'ticketing',
      },
      eventId,
      teamUuid,
    });

    return {
      status: 200,
      folio: response.data.entry,
    };
  } catch (e) {
    e.message =
      'There was an error in configuring your event.  Please refresh the page; if this issue persists, contact support';
    logError(e, 'CreateFolioFromImplied', {
      teamUuid,
      eventId,
      teamFolioUuid,
    });
    return {
      status: 400,
      folio: {},
    };
  }
};

export const AssignFolioToSalesChannel = async (
  folioId,
  salesChannelId,
  teamUuid,
) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/salesChannels/' + salesChannelId + '/folios/' + folioId,
      method: 'put',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
    });
    return {
      status: response.status,
      salesChannelId,
    };
  } catch (e) {
    logError(e, 'AssignFolioToSalesChannel', {
      teamUuid,
    });
    return {
      status: e.status,
    };
  }
};

export const RemoveFolioFromSalesChannel = async (
  folioId,
  salesChannelId,
  teamUuid,
) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: '/api/salesChannels/' + salesChannelId + '/folios/' + folioId,
      method: 'delete',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
    });
    return {
      status: response.status,
      salesChannelId,
    };
  } catch (e) {
    logError(e, 'RemoveFolioFromSalesChannel', {
      teamUuid,
    });
    return {
      status: e.status,
    };
  }
};

export const InvalidateTickets = async (
  eventUuid,
  orderId,
  teamUuid,
  ticketIds,
) => {
  try {
    const eventId = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventUuid,
    );
    const params = {
      invalidateTarget: {
        itemUuids: ticketIds,
        targetType: 'some',
      },
    };
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${eventId}/orders/${orderId}/invalidate`,
      method: 'put',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      eventId: eventId,
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
      teamUuid,
    });
    return {
      status: response.status,
    };
  } catch (e) {
    logError(e, 'InvalidateTickets', {
      teamUuid,
    });
    return {
      status: e.status,
    };
  }
};

export const TransferTickets = async (
  eventUuid: string,
  ticketIds: string[],
  recipientEmail: string,
  teamUuid: string,
) => {
  try {
    const eventId = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventUuid,
    );

    const params = {
      itemUuids: ticketIds,
      note: '',
      recipientEmail,
    };

    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${eventId}/itemTransfer`,
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      eventId: eventId,
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
      teamUuid,
    });
    return {
      status: response.status,
    };
  } catch (e) {
    return {
      errors: e.response.data.errors,
    };
  }
};

export const CancelOrder = async (eventUuid, orderId, teamUuid, ticketIds) => {
  try {
    const eventId = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventUuid,
    );
    const params = {
      cancelTarget: {
        itemUuids: ticketIds,
        targetType: 'some',
      },
    };
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${eventId}/orders/${orderId}/cancel`,
      method: 'put',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      eventId: eventId,
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
      teamUuid,
    });
    return {
      status: response.status,
    };
  } catch (e) {
    logError(e, 'CancelOrder', {
      teamUuid,
    });
    return {
      status: e.status,
    };
  }
};

export const CreateOrder = async ({
  orderPayload,
  eventUuid,
  teamUuid,
  folioId,
}) => {
  try {
    const eventId = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventUuid,
    );

    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${folioId}/orders/`,
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      eventId: eventId,
      headers: {
        'Content-Type': 'application/json',
      },
      data: orderPayload,
      teamUuid,
    });
    return {
      status: response.status,
      entry: response,
    };
  } catch (e: any) {
    if (e.response && e.response.data) {
      e.message = e.message + ' ' + e.response.data;
    }

    logError(e, 'CreateOrder', {
      teamUuid,
    });
    return {
      status: e.status,
    };
  }
};

export const MakeWristbandKnown = async ({
  rfidUid,
  securityCode,
  ticketId,
  eventUri,
  teamUuid,
}) => {
  try {
    const eventId = window.jwtCertSDK.uriToResource(eventUri).resourceId;
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${eventUri}/wristband/`,
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      eventId: eventId,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        rfidUid: rfidUid,
        securityCode: securityCode,
        itemUuid: ticketId,
      },
      teamUuid,
    });
    return {
      status: response.status,
      entry: response,
    };
  } catch (e) {
    logError(e, 'MakeWristbandKnown', {
      teamUuid,
    });
    return {
      status: e.status,
    };
  }
};

export const ConfirmAdminTopUp = async ({
  folioId,
  eventUuid,
  teamUuid,
  orderId,
  topUpTypeId,
  confirmationType,
}) => {
  try {
    const eventId = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventUuid,
    );

    const customToken = await window.agent.generateResourceJCT(
      `cb:team/${teamUuid}`,
      {
        aud: window.ResourceAuthorizer.getServiceUrl('sales', '/api'),
        confirmManualPaymentOfOrderId: orderId,
      },
      60,
    );

    console.log(customToken);

    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${folioId}/orders/${orderId}/confirmPayment`,
      method: 'put',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      eventId: eventId,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + customToken,
      },
      data: {
        confirmationType: confirmationType,
        topupTypeUuid: topUpTypeId,
        confirmManualPaymentOfOrderId: orderId,
      },
      teamUuid,
    });
    return {
      status: response.status,
      entry: response.entry,
    };
  } catch (e) {
    logError(e, 'CreateOrder', {
      teamUuid,
    });
    return {
      status: e.status,
    };
  }
};

export const dumpWallet = async ({
  eventUuid,
  teamUuid,
  walletId,
  amount,
  refundFee,
}) => {
  try {
    const eventId = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventUuid,
    );
    const amountInCents = amount * 100;
    const feeInCents = refundFee * 100;
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${eventId}/distributedLedger/dumpWallet/${walletId}`,
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      eventId: eventId,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        refundAmount: amountInCents - feeInCents,
        refundFee: feeInCents,
      },
      teamUuid,
    });
    return {
      status: response.status,
      entry: response,
    };
  } catch (e) {
    logError(e, 'dumpWallet', {
      teamUuid,
    });
    return {
      status: e.status,
    };
  }
};

export const GetVendorNameByFolioId = async (folioId, teamUuid) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${folioId}/vendorInfo`,
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid: teamUuid,
    });

    return {
      status: 200,
      response: response.data,
    };
  } catch (e) {
    return {
      status: 400,
      orders: {},
    };
  }
};

export const GetVendorsByFolioIds = async (folioIds, teamUuid) => {
  const maxIdsPerRequest = 100;
  const vendorsData = [];

  try {
    for (
      let currentIndex = 0;
      currentIndex < folioIds.length;
      currentIndex += maxIdsPerRequest
    ) {
      const batchIds = folioIds.slice(
        currentIndex,
        currentIndex + maxIdsPerRequest,
      );
      const queryString = batchIds.map((id) => `vendorIds=${id}`).join('&');

      const response = await window.api.request({
        caller: 'sales.team',
        url: `/api/folios/vendors/vendorlist?${queryString}`,
        method: 'get',
        baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
        teamUuid,
      });

      if (response.status === 200 && response.data && response.data.entries) {
        vendorsData.push(...response.data.entries);
      }
    }
  } catch (error) {
    console.log('Failed to load Vending Locations ', error);
    return [];
  }

  return vendorsData;
};

export const getEventTopUpTypes = async (folioId, teamUuid) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${folioId}/topupTypes`,
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
    });
    return response.data.entries;
  } catch (e) {
    return e;
  }
};

export const createCustomTopUpType = async (folioId, teamUuid, topUpName) => {
  try {
    const data = {
      customName: topUpName,
      type: 'custom-topup',
      isRefundable: false,
    };

    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${folioId}/topupTypes`,
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
      data,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const checkIsCanDeleteEventTopUp = async (
  folioId,
  teamUuid,
  topUpId,
) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${folioId}/topupTypes/${topUpId}/canDelete`,
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
    });
    return response.data;
  } catch (e) {
    return e;
  }
};

export const deleteTopUpType = async (folioId, teamUuid, topUpId) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${folioId}/topupTypes/${topUpId}`,
      method: 'delete',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const GetCustomerNotes = async (
  eventId: string,
  accountId: string,
  teamUuid: string,
) => {
  try {
    const eventUri = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventId,
    );

    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${eventUri}/accounts/${accountId}/notes`,
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid: teamUuid,
    });

    return {
      status: 200,
      response: response.data.entries as CustomerNote[],
    };
  } catch (e) {
    return {
      status: 400,
      orders: {},
    };
  }
};

export const CreateCustomerNote = async (
  message,
  eventId: string,
  accountId: string,
  teamUuid: string,
) => {
  try {
    const eventUri = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventId,
    );

    const data = {
      deletedOn: null,
      modifiedOn: new Date(),
      message,
    };

    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${eventUri}/accounts/${accountId}/notes`,
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid: teamUuid,
      data,
    });

    return {
      status: 200,
      response: response.data.entry as CustomerNote,
    };
  } catch (e) {
    return {
      status: 400,
      orders: {},
    };
  }
};

export const RemoveCustomerNote = async (
  noteId: string,
  eventId: string,
  accountId: string,
  teamUuid: string,
) => {
  try {
    const eventUri = await window.ResourceAuthorizer.getResourceFullURI(
      'cb:event/' + eventId,
    );

    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/folios/${eventUri}/accounts/${accountId}/notes/${noteId}`,
      method: 'delete',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid: teamUuid,
    });

    return {
      status: response.status,
    };
  } catch (e) {
    return {
      status: 400,
      orders: {},
    };
  }
};

export const GetOrderNotes = async (orderId, teamUuid) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/orders/${orderId}/notes`,
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid: teamUuid,
    });

    return {
      notes: response.data.entries,
      status: response.status,
    };
  } catch (e) {
    return {
      status: 400,
      notes: [],
    };
  }
};

export const CreateOrderNote = async (orderId, teamUuid, message) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/orders/${orderId}/notes`,
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid: teamUuid,
      data: {
        message: message,
      },
    });

    return {
      status: response.status,
    };
  } catch (e) {
    return {
      status: 400,
    };
  }
};

export const DeleteOrderNote = async (orderId, teamUuid, noteId) => {
  try {
    const response = await window.api.request({
      caller: 'sales.team',
      url: `/api/orders/${orderId}/notes/${noteId}`,
      method: 'delete',
      baseURL: window.ResourceAuthorizer.getServiceUrl('sales'),
      teamUuid: teamUuid,
    });

    return {
      status: response.status,
    };
  } catch (e) {
    return {
      status: 400,
    };
  }
};
