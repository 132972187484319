import React from 'react';
import Loader from '../../loaders/TableLoader.js';

Loading.defaultProps = {
  expandedWidth: true,
  expandedHeight: true,
};

function Loading({ expandedWidth, expandedHeight }) {
  const containerClasses = `
    ${expandedWidth && 'tw-w-screen '}
    ${expandedHeight && 'tw-h-screen '}
    tw-bg-legacy-1
  `;

  return (
    <div className={containerClasses}>
      <Loader color="primary" />
    </div>
  );
}

export default Loading;
