import 'react-app-polyfill/stable';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import 'assets/css/tailwind.css';
import 'assets/css/seats.io.css';

import React, { Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'helpers/context';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import ErrorModal from 'components/errors/modals/AppLevel';
import ApplicationInitialization from 'components/authentication/ApplicationInitialization';
import ReactDOM from 'react-dom';
import loadable from '@loadable/component';
import ReactGa from 'react-ga4';
import { FlagProvider } from '@unleash/proxy-client-react';
import 'helpers/fontAwesome';
import 'helpers/localization';
const ErrorLayout = loadable(() => import('layouts/Error'));
const PublicLayout = loadable(() => import('layouts/Public'));
const UpdatesLayout = loadable(() => import('layouts/Updates'));
const ProducerLayout = loadable(() => import('layouts/Producer'));
const VendorLayout = loadable(() => import('layouts/Vendor'));

const featureFlagConfig = {
  url: 'https://unleash.intellitix.com/api/frontend',
  clientKey:
    '*:development.26677e8264ac3d723671a334c31e87aaa32d41331a1b29087693d841',
  refreshInterval: 3600, // 1 Hour interval
  appName: 'Console',
};

Sentry.init({
  environment: process?.env?.REACT_APP_ENV,
  dsn: 'https://76d84be9158fbd15ea2574efe90c87bd@o4505918946672640.ingest.sentry.io/4506393984368640',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Sentry.setTag('environment', process?.env?.REACT_APP_ENV);

if (
  process.env.REACT_APP_ENV === 'production' &&
  process.env.REACT_APP_ENV_GOOGLE_ANALYTICS !== '' &&
  process.env.REACT_APP_ENV_GOOGLE_ANALYTICS !== undefined
) {
  ReactGa.initialize(process.env.REACT_APP_ENV_GOOGLE_ANALYTICS);
}

console.log(
  'Intellitix Admin Console',
  '|',
  'Environment: ',
  process?.env?.REACT_APP_ENV?.toUpperCase(),
  '|',
  'Version: ',
  process.env.REACT_APP_VERSION,
  '|',
  'Build: ',
  process.env.REACT_APP_BUILD,
);
window.onerror = function (msg) {
  console.log(msg);
};

const App = () => {
  useEffect(() => {
    ErrorLayout.preload();
    PublicLayout.preload();
    UpdatesLayout.preload();
  }, []);

  return (
    <Suspense fallback="Loading...">
      <ApplicationInitialization>
        <Provider>
          <FlagProvider config={featureFlagConfig}>
            <HelmetProvider>
              <Router basename={process.env.PUBLIC_URL}>
                <Router fallback={<span />}>
                  <Switch>
                    <Route path="/errors" component={ErrorLayout} />
                    <Route path="/public" component={PublicLayout} />
                    <Route path="/updates" component={UpdatesLayout} />
                    <Route path="/producer" component={ProducerLayout} />
                    <Route path="/vendor" component={VendorLayout} />
                    <Redirect from="/" to="/public/login" />
                  </Switch>
                  <ToastContainer autoClose={5000} position="top-center" />
                  <div>
                    <ErrorModal
                      closeBtn="Close"
                      retryBtn="Retry"
                      refreshBtn="Refresh"
                    />
                  </div>
                </Router>
              </Router>
            </HelmetProvider>
          </FlagProvider>
        </Provider>
      </ApplicationInitialization>
    </Suspense>
  );
};

/* eslint-disable-next-line */
ReactDOM.render(<App />, document.getElementById('main'));
