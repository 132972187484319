import { data } from 'currency-codes';
import { getAllISOCodes } from 'iso-country-currency';

const TOP_CURRENCIES = ['CAD', 'USD', 'EUR'];
const TOP_COUNTRIES = [
  'Canada',
  'United States',
  'Belgium',
  'France',
  'Switzerland',
];

/**
 * CAD, USD, EUR at the top
 */
export const getTopCurrencies = () => {
  const currencies = [
    ...data.filter(({ code }) => TOP_CURRENCIES.includes(code)),
  ];

  return currencies;
};

/**
 * Get Currency map as value: "", label: ""
 * ex: "CAD", "Canadian Dollar"
 */
export const getCurrenciesAsValueLabelMap = () => {
  const currencies = getTopCurrencies();
  const currencyValueLabelMap = [];

  currencies.forEach(({ code, currency }) => {
    currencyValueLabelMap.push({ value: code, label: currency });
  });

  return currencyValueLabelMap;
};

/**
 * Get ISO Country Map
 */
export const getCountriesMap = () => {
  const countries = getAllISOCodes();

  const topCountries = [
    ...countries.filter(({ countryName }) =>
      TOP_COUNTRIES.includes(countryName),
    ),
  ];

  const mappedCountryCode = topCountries.map(({ countryName }) => {
    return { value: countryName, label: countryName };
  });

  return mappedCountryCode;
};

/**
 * Find ISO Currency from ISO Country name
 */
export const getCountryCurrencyByCountryName = (country) => {
  const countries = getAllISOCodes();

  if (country === 'Switzerland') return 'EUR'; // Edge Case, will set EUR for Switzerland

  const currency = countries.find(({ countryName }) => countryName === country);

  if (!currency) return '';

  return currency.currency;
};

/**
 * Find ISO Currency symbol from currencyCode
 */
export const getCurrencySymbolForCurrencyCode = (currencyCode) => {
  const countries = getAllISOCodes();

  const currency = countries.find(({ currency }) => currency === currencyCode);

  if (!currency) return '$';

  return currency.symbol;
};

export const buildCurrencyBalance = (currency, balance) => {
  const currencySymbol = getCurrencySymbolForCurrencyCode(currency);
  return buildCurrencyBalanceWithSymbol(currency, currencySymbol, balance);
};

export const buildCurrencyBalanceWithSymbol = (
  currency,
  currencySymbol,
  balance,
) => {
  // Incase user didn't pass currency
  const symbol = currencySymbol ? currencySymbol : '$';

  if (currency && currency === 'EUR') {
    return `${balance}${symbol}`;
  }
  return `${symbol}${balance}`;
};
