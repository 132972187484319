const webAppRegExp = /^https:\/\/cb-([^.]+)-([^.]+)\.web\.app(\/.+)?$/;

export function axiosUseDirectFunctionUrl(
  axiosInstance,
  defaultRegion = 'us-central1',
) {
  axiosInstance.interceptors.request.use(function (config) {
    const key = typeof config.baseURL === 'string' ? 'baseURL' : 'url';
    const matches = config[key].match(webAppRegExp);
    if (matches) {
      const region = defaultRegion;
      const service = matches[1];
      const env = matches[2];
      const restUrl = matches[3] ?? '';

      if (service === 'middleware') {
        config.baseURL = `https://${region}-cb-${service}-${env}.cloudfunctions.net/api${restUrl}`;
      } else {
        config.baseURL = `https://${region}-cb-${service}-${env}.cloudfunctions.net/main${restUrl}`;
      }
    }

    return config;
  });
}
