import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { InitAxios } from 'helpers/api/_axios';
import { toast } from 'react-toastify';
import {
  osName,
  osVersion,
  browserName,
  browserVersion,
} from 'react-device-detect';
// import { getItemFromStore } from 'helpers/utils';

export const UserDef = {
  init: false,
  auth: false,
  // functionality for accepting changed updates are not fully implemented, thus disabling for now
  acceptUpdates: true, //getItemFromStore('sfd2432', '') === 'true' ? true : false,
  uid: null,
  device: null,
  loggedOut: false,
  isAnonymous: false,
  emailVerified: false,
  role: null,
  permissions: [null],
  emailAddress: null,
  displayName: null,
  firstName: null,
  lastName: null,
  refreshToken: null,
  metadata: {
    creationTime: null,
    lastSignInTime: null,
  },
};

export const AuthInit = async (setUser, setAgent) => {
  if (!firebase.apps.length) {
    await firebase.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_APP_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_APP_ID,
    });
    await firebase.auth();
    // Removed by Antoine Robidoux on 2022-03-31
    //
    // If session storage is desired for firebase auth, then all of agent's
    // storage should also be kept to session only
    // .setPersistence(firebase.auth.Auth.Persistence.SESSION);
    InitAxios();
    // register listener to firebase auth change, triggered when user signs in or out
    firebase
      .auth()
      .onAuthStateChanged(curryFirebaseAuthStateChanged(setUser, setAgent));
  }
};

/**
 * Initialize a brand new agent
 */
async function initNewAgent(firebaseUser) {
  const [rsaKey, crowdblinkAccount] = await Promise.all([
    window.jwtCertSDK.Agent.generate_key(),
    initCrowdblinkAccount(firebaseUser),
  ]);

  const deviceName =
    osName + ' ' + osVersion + ' - ' + browserName + ' ' + browserVersion;

  const identity = {
    name: firebaseUser.displayName,
    email: firebaseUser.email,
    sub: crowdblinkAccount.sub,
    misc: {},
  };

  const agent = new window.jwtCertSDK.Agent(identity, rsaKey, deviceName);

  return agent;
}

/**
 * finalize initialization of an agent, either brand new instance, or a freshly
 * unserialized one
 */
function inflateAgent(agent) {
  //agent.deviceName;
  agent.dbStoreCertificate = async (JSC, nickname) => {
    localStorage.setItem(
      'cert:' + agent.identity.sub + ':' + agent.keypair.keyid + ':' + nickname,
      JSON.stringify({
        raw: JSC.raw,
        certificate_id: JSC.certificate_id,
      }),
    );
  };
  agent.dbGetCertificate = async (nickname) => {
    try {
      const cert = JSON.parse(
        localStorage.getItem(
          'cert:' +
            agent.identity.sub +
            ':' +
            agent.keypair.keyid +
            ':' +
            nickname,
        ),
      );
      return cert && cert.hasOwnProperty('raw') ? cert.raw : null;
    } catch (e) {
      return null;
    }
  };

  const rootAuthorityClient = new window.jwtCertSDK.AxiosHTTPClient(
    window.api,
    window.ResourceAuthorizer.getServiceUrl('auth', '/api/cert'),
  );
  agent.addRootAuthority(rootAuthorityClient);

  //registration of non-default resources
  // - manages a list of resources
  const resourceAuthorizer = new window.ResourceAuthorizer(
    {
      resources: {
        cb: {
          'team.event': {
            url: {
              service: 'event',
              path: '/api/events/{eventId}/autz',
              aud: '/api',
            },
            auth: 'cb:team/{teamUuid}',
            resourceIdMap: ['teamUuid', 'eventId'],
          },
          'team.payment.merchantAccount': {
            url: {
              service: 'payment',
              path: '/api/merchant/account/{merchantAccountId}/autz',
              aud: '/api',
            },
            auth: 'cb:team/{teamUuid}',
            resourceIdMap: ['teamUuid', 'merchantAccountId'],
          },
          'sales-item': {
            url: {
              service: 'sales',
            },
          },
          'team.ledger': {
            url: {
              service: 'distributed-ledger',
              path: '/api/ledger/{ledgerUuid}/autz',
              aud: '/api',
            },
            auth: 'cb:team/{teamUuid}',
            resourceIdMap: ['teamUuid', 'ledgerUuid'],
          },
        },
      },
    },
    true,
  );
  resourceAuthorizer.bindToAgent(agent);
  agent.resourceAuthorizer = resourceAuthorizer;

  // launching this now to warm up the agent, ensuring a valid certificate
  // exists. We are not awaiting for it, since we will not be using the
  // response. A returning agent that still has a valid certificate will finish
  // this call without making a http call
  agent.generateAuthenticationJCT({}).catch(function (err) {
    console.error('Failed to generate warmup authentication token', err);
  });

  // The token manager will allow generated token to be reused when applicable
  const tokenManager = new window.jwtCertSDK.TokenManager(agent);
  // assigned to the agent object, for ease of use
  agent.tokenManager = tokenManager;

  /******** Registering token generator ***************************************/

  // authentication token meant to be used with the event micro-service
  tokenManager.registerTokenGenerator('auth:event', (agent, ttl) =>
    agent.generateAuthenticationJCT(
      {
        aud: window.ResourceAuthorizer.getServiceUrl('event', '/api'),
      },
      ttl,
    ),
  );

  tokenManager.registerTokenGenerator('auth:payment', (agent, ttl) =>
    agent.generateAuthenticationJCT(
      {
        aud: window.ResourceAuthorizer.getServiceUrl('payment', '/api'),
      },
      ttl,
    ),
  );

  // authentication token meant to be used with the teams micro-service
  tokenManager.registerTokenGenerator('auth:team', (agent, ttl) =>
    agent.generateAuthenticationJCT(
      {
        aud: window.ResourceAuthorizer.getServiceUrl('team', '/api'),
      },
      ttl,
    ),
  );

  // authentication token meant to be used with the access-control micro-service
  tokenManager.registerTokenGenerator('auth:access-control', (agent, ttl) =>
    agent.generateAuthenticationJCT(
      {
        aud: window.ResourceAuthorizer.getServiceUrl('access-control', '/api'),
      },
      ttl,
    ),
  );

  // team resource authorization token meant to be used with teams micro-service
  tokenManager.registerTokenGenerator(
    'res:team:teamUuid',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team/' + args.teamUuid,
        {
          aud: window.ResourceAuthorizer.getServiceUrl('team', '/api'),
        },
        ttl,
      ),
    // sub-keyed by teamUuid
    (key, args) => key + ':' + args.teamUuid,
  );

  // team resource authorization token meant to be used with events micro-service
  tokenManager.registerTokenGenerator(
    'res:team:event:teamUuid',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team/' + args.teamUuid,
        {
          aud: window.ResourceAuthorizer.getServiceUrl('event', '/api'),
        },
        ttl,
      ),
    // sub-keyed by teamUuid
    (key, args) => key + ':' + args.teamUuid,
  );

  tokenManager.registerTokenGenerator(
    'res:team:payment:teamUuid',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team/' + args.teamUuid,
        {
          aud: window.ResourceAuthorizer.getServiceUrl('payment', '/api'),
        },
        ttl,
      ),
    // sub-keyed by teamUuid
    (key, args) => key + ':' + args.teamUuid,
  );

  tokenManager.registerTokenGenerator(
    'res:teamUuid:ledger',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team/' + args.teamUuid,
        {
          aud: window.ResourceAuthorizer.getServiceUrl(
            'distributed-ledger',
            '/api',
          ),
        },
        ttl,
      ),
    // sub-keyed by teamUuid
    (key, args) => key + ':' + args.teamUuid,
  );

  // micro-service
  tokenManager.registerTokenGenerator(
    'res:sales:eventId:teamUuid',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team.event/' + args.teamUuid + '/' + args.eventId,
        {
          aud: window.ResourceAuthorizer.getServiceUrl('sales', '/api'),
        },
        ttl,
      ),
    // sub-keyed by eventId
    (key, args) => key + ':' + args.teamUuid + args.eventId,
  );

  tokenManager.registerTokenGenerator(
    'res:sales:teamUuid',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team/' + args.teamUuid,
        {
          aud: window.ResourceAuthorizer.getServiceUrl('sales', '/api'),
        },
        ttl,
      ),
    // sub-keyed by teamUuid
    (key, args) => key + ':' + args.teamUuid,
  );

  tokenManager.registerTokenGenerator(
    'res:access-control:eventId:teamUuid',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team.event/' + args.teamUuid + '/' + args.eventId,
        {
          aud: window.ResourceAuthorizer.getServiceUrl(
            'access-control',
            '/api',
          ),
        },
        ttl,
      ),
    // sub-keyed by eventId
    (key, args) => key + ':' + args.teamUuid + args.eventId,
  );

  // event resource authorization token meant to be used with the payments
  // micro-service
  tokenManager.registerTokenGenerator(
    'res:payments:eventId',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:payments/' + args.eventId,
        {
          aud: window.ResourceAuthorizer.getServiceUrl('payments', '/api'),
        },
        ttl,
      ),
    // sub-keyed by eventId
    (key, args) => key + ':' + args.eventId,
  );

  // event resource authorization token meant to be used with the datariver
  tokenManager.registerTokenGenerator(
    'res:datariver:eventId:teamUuid',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team.event/' + args.teamUuid + '/' + args.eventId,
        {
          // TODO update this to the desired value for the datariver
          aud: window.ResourceAuthorizer.getServiceUrl('datariver'),
        },
        ttl,
      ),
    // sub-keyed by teamUuid and eventId
    (key, args) => key + ':' + args.teamUuid + args.eventId,
  );

  // resource authorization token meant to be used with the datariver
  tokenManager.registerTokenGenerator(
    'res:datariver:teamUuid',
    (agent, ttl, args) =>
      agent.generateResourceJCT(
        'cb:team/' + args.teamUuid,
        {
          aud: window.ResourceAuthorizer.getServiceUrl('datariver'),
        },
        ttl,
      ),
    // sub-keyed by teamUuid
    (key, args) => key + ':' + args.teamUuid,
  );

  /******** End of token generator registration********************************/
}

/**
 * Create or retrieve the crowdblink account information tied to the firebase
 * user's email
 */
async function initCrowdblinkAccount(firebaseUser) {
  const response = await window.api.request({
    url: '/api/account',
    method: 'post',
    caller: 'auth',
    headers: {
      authorization: 'Bearer ' + (await firebaseUser.getIdToken()),
    },
    baseURL: window.ResourceAuthorizer.getServiceUrl('auth'),
  });
  return response.data;
}

/**
 * firebase auth handler, meant to be registered with
 * ```
 * firebase.auth().onAuthStateChanged(firebaseAuthStateChanged)
 * ```
 */
function curryFirebaseAuthStateChanged(setUser, setAgent) {
  return async function firebaseAuthStateChanged(firebaseUser) {
    if (firebaseUser && firebaseUser.emailVerified === true) {
      let agent = null;
      const serializedAgent = localStorage.getItem('agent');
      // determine if this is a new user, if not, agent should be unserialized
      if (serializedAgent) {
        agent = window.jwtCertSDK.Agent.unserialize(serializedAgent);
        if (agent.identity.email === firebaseUser.email) {
          // user is returning, we can use it
        } else {
          // new user, serialized agent and certificate cache should be cleared
          localStorage.removeItem('agent');
          agent = null;
        }
      }

      // Generate a new agent if there was none valid found
      if (!agent) {
        agent = await initNewAgent(firebaseUser);
      }

      // get our agent instance ready to work
      inflateAgent(agent);
      setAgent(agent);

      // store the agent for reuse, ex: when the user refreshes the page
      localStorage.setItem('agent', agent.serialize());
      // localStorage.setItem('slk44928sdf', JSON.stringify({
      //     uid: firebaseUser.uid,
      //     emailVerified: firebaseUser.emailVerified,
      //     email: firebaseUser.email,
      //     displayName: firebaseUser.displayName,
      //     creationTime: firebaseUser.metadata.creationTime,
      //     lastSignInTime: firebaseUser.metadata.lastSignInTime
      // }));

      window.agent = agent;

      // let react know
      setUser({
        ...UserDef,
        auth: true,
        uid: firebaseUser.uid,
        device: agent.deviceName,
        loggedOut: false,
        emailVerified: firebaseUser.emailVerified,
        role: 'producer',
        permissions: ['all'],
        emailAddress: firebaseUser.email,
        displayName: firebaseUser.displayName,
        metadata: {
          creationTime: firebaseUser.creationTime,
          lastSignInTime: firebaseUser.lastSignInTime,
        },
      });
    } else {
      await LogoutAuth();
      setUser(UserDef);
      setAgent(null);
    }
  };
}

export const CreateUser = async (
  emailAddress,
  password,
  firstName,
  lastName,
) => {
  try {
    const emailLower = emailAddress ? emailAddress.toLowerCase() : emailAddress;
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(emailLower, password);
    await response.user.updateProfile({
      displayName: firstName + ' ' + lastName,
    });
    await response.user.sendEmailVerification();
    return true;
  } catch (err) {
    if (err.code === 'auth/email-already-in-use') {
      toast.error('Email address is already in use');
    } else {
      toast.error(err.message);
    }
    return false;
  }
};

export const ResendVerificationLinkAuth = async (email, password) => {
  try {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    if (
      response.hasOwnProperty('user') &&
      response.user.emailVerified === false
    ) {
      await firebase.auth().currentUser.sendEmailVerification();
    } else {
      return false;
    }

    return true;
  } catch (err) {
    toast.error(err.message);
    return false;
  }
};

export const LoginAuth = async (email, password) => {
  try {
    const emailLower = email ? email.toLowerCase() : email;
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(emailLower, password);
    return response;
  } catch (err) {
    switch (err.code) {
      case 'auth/invalid-email':
        toast.error('Invalid email address');
        break;
      case 'auth/user-disabled':
        toast.error('Account disabled - please contact customer support');
        break;
      case 'auth/user-not-found':
      case 'auth/wrong-password':
      default:
        toast.error('Login failed.');
        break;
    }
    return {
      state: false,
      code: err.code,
      message: err.message,
    };
  }
};

export const LoginWithGoogle = async () => {
  try {
    const response = firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider());
    if (response) {
    }
    return response;
  } catch (err) {
    return {
      state: false,
      code: err.code,
      message: err.message,
    };
  }
};

export const ConfirmEmailAddressAuth = async () => {
  try {
    const code = new URLSearchParams(window.location.search).get('oobCode');
    const check = await firebase.auth().checkActionCode(code);
    if (check.data.email) {
      const apply = await firebase.auth().applyActionCode(code);
      if (apply) {
      }
      return true;
    }
    return false;
  } catch (err) {
    return {
      state: false,
      code: err.code,
      message: err.message,
    };
  }
};

export const PasswordResetLinkAuth = async (email) => {
  try {
    const emailLower = email ? email.toLowerCase() : email;
    const response = await firebase.auth().sendPasswordResetEmail(emailLower);
    if (response) {
    }
    return true;
  } catch (err) {
    return {
      state: false,
      code: err.code,
      message: err.message,
    };
  }
};

export const PasswordResetLinkValidateAuth = async (code) => {
  try {
    const response = await firebase.auth().verifyPasswordResetCode(code);
    return response ? response : false;
  } catch (err) {
    return false;
  }
};

export const PasswordResetLinkApplyAuth = async (code, password) => {
  try {
    const response = await firebase.auth().confirmPasswordReset(code, password);
    if (response) {
    }
    return true;
  } catch (err) {
    return {
      state: false,
      code: err.code,
      message: err.message,
    };
  }
};

/**
 * Removes every entry in localStorage that have a key starting with 'cert:'
 */
function clearCertificateCache() {
  let it = 0;
  while (true) {
    const key = localStorage.key(it);
    if (key === null) {
      // we're done
      break;
    }

    // if the key starts with 'cert:', remove it
    if (key.substring(0, 5) === 'cert:') {
      localStorage.removeItem(key);
      // no need to increment it here, the remaining keys all shifted back when
      // we removed the item
    } else {
      // otherwise move to the next one
      it++;
    }
  }
}

export const LogoutAuth = async () => {
  try {
    if (window.agent) {
      await window.agent.revokeAllCertificates();
      delete window.agent;
    }

    const response = await firebase.auth().signOut();

    // clear any remnance of certificates in localStorage
    try {
      clearCertificateCache();
    } catch (err) {
      // not the end of the world, ignore it
    }

    localStorage.removeItem('agent');
    localStorage.removeItem('activeTeamUuid');
    sessionStorage.removeItem('activeTeamUuid');
    localStorage.removeItem('slk44928sdf');

    window.authCached = false;

    if (response) {
    }
    return true;
  } catch (err) {
    return {
      state: false,
      code: err.code,
      message: err.message,
    };
  }
};

export const isLoggedIn = () => {
  return !!window.agent;
};
