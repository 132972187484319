import { logError } from 'helpers/logging';
import { CreateTeamFolio } from './folios';
import { firstBy } from 'thenby';
import { TeamRole, getScopesForTeamRole } from 'helpers/teamRoles';
import { AxiosError } from 'axios';

export const GetTeamList = async (inflateTeams = false) => {
  try {
    const response = await window.api.request({
      caller: 'team',
      url: '/api/user/teams',
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
      params: {
        inflateTeams,
      },
    });
    const teams = response.data.entries.filter(
      function filterOutVendors(entry) {
        // folding permissions into team object
        entry.team.permissions = entry.permissions[0];
        return (
          !entry?.team?.teamType ||
          entry.team.teamType === 'organization' ||
          entry.team.teamType === 'organizational_unit'
        );
      },
    );

    return teams.sort(
      firstBy((team1, team2) =>
        team1.team.teamType === team2.team.teamType
          ? 0
          : team1.team.teamType === 'organization'
            ? -1
            : 1,
      ).thenBy((team1, team2) => (team1.team.name < team2.team.name ? -1 : 1)),
    );
  } catch (e) {
    logError(e, 'GetTeamList', {});
    return {
      status: 400,
    };
  }
};

export const GetTeam = async (teamUuid, opts?: { silence403?: boolean }) => {
  try {
    const response = await window.api.request({
      caller: 'team',
      url: '/api/teams/' + teamUuid,
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
    });
    const team = response.data.entry;

    return { status: 200, team: team };
  } catch (e) {
    if (opts?.silence403 && (e as AxiosError)?.response?.status === 403) {
      // silenced
    } else {
      logError(e, 'GetTeam', { teamUuid });
    }
    return {
      status: 400,
    };
  }
};

export const GetRegions = async (teamUuid) => {
  try {
    const response = await window.api.request({
      caller: 'team.team',
      url: '/api/teams',
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
      teamUuid,
      params: {
        inflateTeams: true,
      },
    });
    const teams = response.data.entries.filter(function filterOutVendors(team) {
      return (
        !team?.team?.teamType || team.team.teamType === 'organizational_unit'
      );
    });
    return teams;
  } catch (e) {
    logError(e, 'GetRegions', {});
    return {
      status: 400,
    };
  }
};

export const CreateRegion = async (data, teamUuid) => {
  try {
    let body = {
      name: data.name,
      parentTeamUuid: data.parentTeamUuid,
      teamType: 'organizational_unit',
    };

    const response = await window.api.request({
      caller: 'team.team',
      url: '/api/teams',
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
      teamUuid,
      data: body,
    });
    const team = response.data.entry;
    const teamFolioResponse = await CreateTeamFolio(team.uuid, teamUuid);
    if (teamFolioResponse.status === 200) {
      return {
        status: 200,
        team,
      };
    } else {
      throw new Error('Failed to create team folio');
    }
  } catch (e) {
    logError(e, 'CreateTeam', {});
    return {
      status: 400,
    };
  }
};

export const GetTeamMembers = async (teamUuid) => {
  try {
    const response = await window.api.request({
      caller: 'team.team',
      url: '/cb-teams/teams/' + teamUuid + '/members',
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('middleware'),
      teamUuid,
    });
    const teams = response.data.entries;
    return teams;
  } catch (e) {
    logError(e, 'GetTeamList', {});
    return [];
  }
};

export const GetTeamInvites = async (teamUuid) => {
  try {
    const response = await window.api.request({
      caller: 'team',
      url: '/api/teams/' + teamUuid + '/invitations',
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
    });
    const teams = response.data.entries;
    return teams;
  } catch (e) {
    logError(e, 'GetTeamInvites', {});
    return [];
  }
};

export const GetPendingInvites = async () => {
  try {
    const response = await window.api.request({
      caller: 'team',
      url: '/api/user/pendingInvitations',
      method: 'get',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
    });
    const invitations = response.data.entries.filter(
      (invitation) => !invitation.acceptedOn,
    );
    return invitations;
  } catch (e) {
    logError(e, 'GetPendingInvites', {});
    return [];
  }
};

export const RevokeUserAccess = async (teamUuid, userEmail) => {
  try {
    await window.api.request({
      caller: 'team',
      url: `/api/teams/${teamUuid}/members/revoke/${userEmail}`,
      method: 'delete',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
      teamUuid,
    });
  } catch (e) {
    throw new Error(e.response.data.msg);
  }
};

export const AcceptInvite = async (invitationId) => {
  try {
    const response = await window.api.request({
      caller: 'team',
      url: '/api/user/pendingInvitations/' + invitationId + '/accept',
      method: 'put',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
    });
    const team = response.data.membership;
    return team;
  } catch (e) {
    logError(e, 'AcceptInvite', {});
    return {};
  }
};

export const CreateTeam = async (type, data) => {
  try {
    let body;

    switch (type) {
      case 'organization':
        body = {
          name: data.doingBusinessAs,
          teamType: 'organization',
          organization: {
            legalBusinessName: data.legalBusinessName || '',
            doingBusinessAs: data.doingBusinessAs || '',
            legalAddress: {
              address1: data.address1 || '',
              address2: data.address2 || '',
              city: data.city || '',
              country: data.country || 'US',
              state: data.state || '',
              zip: data.zip || '',
            },
            taxNumber: data.taxNumber || '',
            phoneNumber: data.phoneNumber || '',
            contactName: data.contactName || '',
            businessType: 'Corporation',
          },
        };
        break;
      case 'organizational_unit':
        body = {
          name: data.name,
          parentTeamUuid: data.parentTeamUuid,
          teamType: 'organizational_unit',
        };
        break;
      default:
        body = {};
        break;
    }

    const response = await window.api.request({
      caller: 'team',
      url: '/api/teams',
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
      data: body,
    });
    const team = response.data.entry;
    const teamFolioResponse = await CreateTeamFolio(team.uuid);
    if (teamFolioResponse.status === 200) {
      return {
        status: 200,
        team,
      };
    } else {
      throw new Error('Failed to create team folio');
    }
  } catch (e) {
    logError(e, 'CreateTeam', {});
    return {
      status: 400,
    };
  }
};

export const UpdateTeam = async (teamUuid, data) => {
  try {
    const response = await window.api.request({
      caller: 'team',
      url: '/api/teams/' + teamUuid,
      method: 'patch',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
      data: {
        ...data,
        modifiedOn: new Date().toISOString(),
      },
    });
    const team = response.data.entry;
    return {
      status: response.status,
      team,
    };
  } catch (e) {
    logError(e, 'CreateTeam', {});
    return {
      status: 400,
    };
  }
};

export const InviteUser = async (
  email,
  teamUuid,
  senderName,
  role: TeamRole,
) => {
  try {
    const emailLower = email ? email.toLowerCase() : email;
    const response = await window.api.request({
      caller: 'team',
      url: '/api/teams/' + teamUuid + '/invitations',
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
      data: {
        scopes: getScopesForTeamRole(role),
        identity: {
          email: emailLower,
        },
        message: senderName || emailLower,
      },
    });
    const team = response.data.entry;
    return {
      status: 200,
      team,
    };
  } catch (e) {
    return {
      status: 400,
      msg: e.response.data.msg,
    };
  }
};

export const GeneratePreApprovedInvite = async (teamUuid, preApprovedJCT) => {
  try {
    const response = await window.api.request({
      caller: 'team',
      url: '/api/teams/' + teamUuid + '/preApproveInvitation',
      method: 'post',
      baseURL: window.ResourceAuthorizer.getServiceUrl('team'),
      data: {
        preApprovedJCT,
      },
    });
    return response.data.token;
  } catch (e) {
    logError(e, 'GeneratePreApprovedInvite', {});
    return {
      status: 400,
    };
  }
};
