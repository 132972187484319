import { arraysMatch } from './utils';

export enum TeamRole {
  Admin = 'admin',
  Support = 'support',
  SystemAdmin = 'systemAdmin',
}

interface RoleWithScopes {
  name: string;
  scopes: string[];
  /* For backward compatibility, so role can still be detected for users with old scopes.
       If you are changing the scope of the role, put the actual value to `scopes` and old to `legacyScopes`
    */
  legacyScopes?: string[][];
}

const rolesMap: Record<TeamRole, RoleWithScopes> = {
  admin: {
    name: 'admin',
    scopes: ['*'],
  },
  systemAdmin: {
    name: 'systemAdmin',
    scopes: ['*', 'system-admin'],
  },
  support: {
    name: 'support',
    scopes: ['*', 'cb:event/*,read'],
    legacyScopes: [['cb:event/*,read']],
  },
};

export const getScopesForTeamRole = (roleName: TeamRole): string[] => {
  const role = rolesMap[roleName];
  return role ? role.scopes : [];
};

export const getRoleForScopes = (
  scopes: string[] | null,
  activeTeam?: any,
): string | null => {
  if (isOrgOwner(activeTeam)) return TeamRole.Admin;

  if (!scopes) return null;

  for (const roleName in rolesMap) {
    const role = rolesMap[roleName];
    const allScopes = [role.scopes].concat(role.legacyScopes || []);

    for (const scope of allScopes) {
      if (arraysMatch(scope, scopes)) {
        return role.name;
      }
    }
  }

  return null;
};

export const isOrgOwner = (activeTeam) => {
  return activeTeam !== undefined && activeTeam.owner
    ? activeTeam.owner
    : false;
};
