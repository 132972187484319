import React from 'react';
import { ApplicationInitializationContext } from 'components/authentication/ApplicationInitialization';

function useApplicationUser() {
  const appInitializationContext = React.useContext(
    ApplicationInitializationContext,
  );

  return React.useMemo(
    () => ({ ...appInitializationContext }),
    [appInitializationContext],
  );
}

export default useApplicationUser;
