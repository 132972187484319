import { useState } from 'react';

export const useGuestsSearchState = () => {
  const [customers, setCustomers] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [selectedEventId, setSelectedEventId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [isShowRecord, setIsShowRecord] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return {
    selectedEventId,
    setSelectedEventId,
    searchText,
    setSearchText,
    customers,
    setCustomers,
    events,
    setEvents,
    isShowRecord,
    setIsShowRecord,
    isLoading,
    setIsLoading,
  };
};
