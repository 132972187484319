export const CB_SUPPORT_URL = 'https://intellitix.zendesk.com/hc/en-us';
export const REGION_LENUSA = 'lenusa';

export const WIDGET_URLS = {
  snd: '//sandbox.widget.crowdblink.com',
  stg: '//staging.widget.crowdblink.com',
  prd: '//widget.crowdblink.com',
};

export const GRAFANA_URLS = {
  snd: 'https://grafana-sandbox.itxregpage.com',
  stg: 'https://grafana-stg.itxregpage.com',
  prd: 'https://grafana-prod.itxregpage.com',
};
