import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';

const AppLevel = ({ closeBtn }) => {
  const [state, setState] = useState({
    active: false,
    message: '',
  });

  window.addEventListener('message', (msg) => {
    //console.log(msg);
    if (msg.data && msg.data.error) {
      setState({
        active: true,
        message: msg.data.description,
      });
    }
  });

  return (
    <Modal
      isOpen={state.active ? true : false}
      backdrop={'static'}
      keyboard={false}
      toggle={() => {}}
      style={{ maxWidth: '500px' }}
    >
      <ModalHeader>Application Error</ModalHeader>
      <ModalBody>
        <Label>{state.message}</Label>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={() => {
            setState({ active: false });
          }}
        >
          {closeBtn}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppLevel;
