import React, {
  useEffect,
  useMemo,
  useState,
  createContext,
  useCallback,
} from 'react';
import { AuthInit } from 'helpers/api/authentication';
import { GetTeam, GetTeamList } from 'helpers/api/teams';
import Loading from 'components/common/Loading';
import yupSetup from 'helpers/yupSetup';
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import { REGION_LENUSA } from 'helpers/constants';

export const ApplicationInitializationContext = createContext({});

function ApplicationInitialization(props) {
  const [user, setUser] = useState();
  const [agent, setAgent] = useState();
  const [activeTeam, setActiveTeam] = useState();
  const [isActiveTeamParentLenusa, setIsActiveTeamParentLenusa] =
    useState(false);
  const [initialTeamLoaded, setInitialTeamLoaded] = useState(false);
  const contextValue = useMemo(
    function contextValueMemo() {
      return {
        user,
        setUser,
        agent,
        setAgent,
        activeTeam,
        setActiveTeam,
        isActiveTeamParentLenusa,
      };
    },
    [
      user,
      setUser,
      agent,
      setAgent,
      activeTeam,
      setActiveTeam,
      isActiveTeamParentLenusa,
    ],
  );

  const setup = useCallback(async () => {
    function applicationInitializationSetUserCallback(aUser) {
      setUser(aUser);
    }
    function applicationInitializationSetAgentCallback(aAgent) {
      setAgent(aAgent);
    }
    await AuthInit(
      applicationInitializationSetUserCallback,
      applicationInitializationSetAgentCallback,
    );
    yupSetup();
  }, [setUser, setAgent]);

  useEffect(() => {
    setup();
  }, [setup]);

  const setActiveTeamFromStore = (team, parentTeamUuid) => {
    setActiveTeam({ ...team.team, owner: team.owner });
    // eslint-disable-next-line unused-imports/no-unused-vars
    parentTeamUuid = team.team.parentTeamUuid;
    setItemToStore('activeTeamUuid', team.team.uuid, sessionStorage);
    setItemToStore('activeTeamUuid', team.team.uuid, localStorage);
  };

  useEffect(() => {
    async function fetchData() {
      // TODO: once permissions are implemented we can re-add this
      // const activeTeamUuid = getItemFromStore(
      //   'activeTeamUuid',
      //   getItemFromStore('activeTeamUuid', null, localStorage),
      //   sessionStorage
      // );
      let parentTeamUuid;
      // TODO: once permissions are implemented we can re-add this
      // if (activeTeamUuid) {
      //   const teamResponse = await GetTeam(activeTeamUuid);
      //   setActiveTeam(teamResponse.team);
      //   parentTeamUuid = teamResponse.team.parentTeamUuid;
      // } else {
      const teams = await GetTeamList(true);
      const activeTeam = getItemFromStore('activeTeamUuid');
      if (teams && teams.length > 0) {
        if (activeTeam && activeTeam !== '') {
          const useTeam = teams.find((teamObj) => {
            return teamObj.team.uuid === activeTeam;
          });
          if (useTeam) {
            setActiveTeamFromStore(useTeam, parentTeamUuid);
          } else {
            setActiveTeamFromStore(teams[0], parentTeamUuid);
          }
        } else {
          setActiveTeamFromStore(teams[0], parentTeamUuid);
        }
      }
      //}
      if (parentTeamUuid) {
        const teamParentResponse = await GetTeam(parentTeamUuid, {
          silence403: true,
        });
        setIsActiveTeamParentLenusa(
          teamParentResponse.team?.name.toLowerCase().includes(REGION_LENUSA)
            ? true
            : false,
        );
      }
      setInitialTeamLoaded(true);
    }
    if (user && user.auth) {
      fetchData();
    } else {
      setInitialTeamLoaded(false);
    }
  }, [user]);

  // check if application is correctly initialized
  const applicationInitialized =
    (user !== undefined && !user.auth) ||
    (user && user.auth && initialTeamLoaded);

  if (!applicationInitialized) {
    // TODO: add proper look and feel
    return <Loading />;
  }

  return (
    <ApplicationInitializationContext.Provider value={contextValue}>
      {props.children}
    </ApplicationInitializationContext.Provider>
  );
}

export default ApplicationInitialization;
