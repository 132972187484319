import * as yup from 'yup';

function yupSetup() {
  yup.setLocale({
    // use constant translation keys for messages without values
    mixed: {
      default: 'common.form.mixed.error.fieldInvalid',
      required: 'common.form.mixed.error.required',
    },
    // must use count variable name in values to be able to use plural from i18next
    number: {
      min: ({ min }) => ({
        key: 'common.form.number.error.fieldTooShort',
        values: { count: min },
      }),
      max: ({ max }) => ({
        key: 'common.form.number.error.fieldTooBig',
        values: { count: max },
      }),
    },
    string: {
      min: ({ min }) => ({
        key: 'common.form.string.error.fieldTooShort',
        values: { count: min },
      }),
      max: ({ max }) => ({
        key: 'common.form.string.error.fieldTooBig',
        values: { count: max },
      }),
      matches: ({ regex }) => ({
        key: 'common.form.string.error.patternMatch',
        values: { regex },
      }),
    },
  });
}

export default yupSetup;
